<script>
	import Grid from '$lib/Components/Layout/Grid/Grid.svelte';
	import Image from '$lib/Components/Layout/Image/Image.svelte';

	export let title = null;
	export let alt = '';
	export let src = null;
	export let subtitle = null;
	export let description = null;
	export let href = null;
	export let subgrid = false;
	export let headingLevel = 'h2';
	export let learnMore = null;
	export let loading = 'eager';
	export let imageClass = 'border-4 border-indow-white';
</script>

<Grid {subgrid} vertical center>
	{#if href}
		<a {href} class="contents">
			<slot name="title">
				{#if title}
					<svelte:element
						this={headingLevel}
						class="mb-0 text-2xl font-bold capitalize">{title}</svelte:element
					>
				{/if}
			</slot>
			<slot name="image">
				{#if src}
					<Image
						{alt}
						{src}
						class={[
							'!mb-0 aspect-square max-w-44 rounded-full',
							imageClass,
						].join(' ')}
						{loading}
					/>
				{/if}
			</slot>
			<slot name="subtitle">
				{#if subtitle}
					<p
						class="mb-0 font-medium !text-indow-blue"
						style="text-wrap: balance;"
					>
						{#if href}
							{subtitle}
						{:else}
							{subtitle}
						{/if}
					</p>
				{/if}
			</slot>
		</a>
	{:else}
		<slot name="title">
			{#if title}
				<svelte:element
					this={headingLevel}
					class="mb-0 text-2xl font-bold capitalize">{title}</svelte:element
				>
			{/if}
		</slot>
		<slot name="image">
			{#if src}
				<Image {alt} {src} class={['!mb-0 max-w-44'].join(' ')} {loading} />
			{/if}
		</slot>

		<slot name="subtitle">
			{#if subtitle}
				<p class="mb-0 font-medium !text-indow-blue">
					{#if href}
						{subtitle}
					{:else}
						{subtitle}
					{/if}
				</p>
			{/if}
		</slot>
	{/if}

	<slot name="description">
		{#if description}
			<p class="mb-0">{description}</p>
		{/if}
	</slot>
	<slot name="learnMore">
		{#if href && learnMore}
			<p class="mb-0 font-medium">
				<a {href}>{typeof learnMore == 'string' ? learnMore : 'Learn More'}</a>
			</p>{/if}
	</slot>
</Grid>
